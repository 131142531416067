// source: gw/provider_coupon/provider_coupon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var domain_enumeration_provider_coupon_target_pb = require('../../domain/enumeration/provider_coupon_target_pb.js');
goog.object.extend(proto, domain_enumeration_provider_coupon_target_pb);
var domain_enumeration_provider_coupon_followup_reply_status_pb = require('../../domain/enumeration/provider_coupon_followup_reply_status_pb.js');
goog.object.extend(proto, domain_enumeration_provider_coupon_followup_reply_status_pb);
var domain_enumeration_service_class_pb = require('../../domain/enumeration/service_class_pb.js');
goog.object.extend(proto, domain_enumeration_service_class_pb);
var domain_provider_coupon_price_pb = require('../../domain/provider_coupon_price_pb.js');
goog.object.extend(proto, domain_provider_coupon_price_pb);
var domain_provider_coupon_pb = require('../../domain/provider_coupon_pb.js');
goog.object.extend(proto, domain_provider_coupon_pb);
var domain_provider_favorite_service_pb = require('../../domain/provider_favorite_service_pb.js');
goog.object.extend(proto, domain_provider_favorite_service_pb);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.DiscountDetailCase', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.OptoutRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.provider_coupon.OptoutResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.oneofGroups_);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.OptoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.OptoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.OptoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.OptoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.displayName = 'proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponDetailDomain: (f = msg.getProviderCouponDetailDomain()) && domain_provider_coupon_pb.ProviderCouponDetailDomain.toObject(includeInstance, f),
    targetUserIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest;
  return proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_provider_coupon_pb.ProviderCouponDetailDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponDetailDomain.deserializeBinaryFromReader);
      msg.setProviderCouponDetailDomain(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setTargetUserIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponDetailDomain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_provider_coupon_pb.ProviderCouponDetailDomain.serializeBinaryToWriter
    );
  }
  f = message.getTargetUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.ProviderCouponDetailDomain provider_coupon_detail_domain = 1;
 * @return {?proto.coconala.protobuf.domain.ProviderCouponDetailDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.getProviderCouponDetailDomain = function() {
  return /** @type{?proto.coconala.protobuf.domain.ProviderCouponDetailDomain} */ (
    jspb.Message.getWrapperField(this, domain_provider_coupon_pb.ProviderCouponDetailDomain, 1));
};


/**
 * @param {?proto.coconala.protobuf.domain.ProviderCouponDetailDomain|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.setProviderCouponDetailDomain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.clearProviderCouponDetailDomain = function() {
  return this.setProviderCouponDetailDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.hasProviderCouponDetailDomain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int32 target_user_ids = 2;
 * @return {!Array<number>}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.getTargetUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.setTargetUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.addTargetUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponRequest.prototype.clearTargetUserIdsList = function() {
  return this.setTargetUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponId: (f = msg.getProviderCouponId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest;
  return proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderCouponId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_coupon_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.prototype.getProviderCouponId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.prototype.setProviderCouponId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.prototype.clearProviderCouponId = function() {
  return this.setProviderCouponId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponRequest.prototype.hasProviderCouponId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponTarget: jspb.Message.getFieldWithDefault(msg, 1, 0),
    serviceId: (f = msg.getServiceId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (reader.readEnum());
      msg.setProviderCouponTarget(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setServiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getServiceId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.ProviderCouponTarget provider_coupon_target = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.getProviderCouponTarget = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.setProviderCouponTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value service_id = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.getServiceId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.clearServiceId = function() {
  return this.setServiceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersRequest.prototype.hasServiceId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.DiscountDetailCase = {
  DISCOUNT_DETAIL_NOT_SET: 0,
  DISCOUNT_RATE: 3,
  DISCOUNT_AMOUNT: 4
};

/**
 * @return {proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.DiscountDetailCase}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.getDiscountDetailCase = function() {
  return /** @type {proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.DiscountDetailCase} */(jspb.Message.computeOneofCase(this, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceClass: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minimumAmount: (f = msg.getMinimumAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountRate: (f = msg.getDiscountRate()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountAmount: (f = msg.getDiscountAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceClass} */ (reader.readEnum());
      msg.setServiceClass(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinimumAmount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountRate(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMinimumAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.ServiceClass service_class = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.ServiceClass}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.getServiceClass = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceClass} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ServiceClass} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.setServiceClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value minimum_amount = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.getMinimumAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.setMinimumAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.clearMinimumAmount = function() {
  return this.setMinimumAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.hasMinimumAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value discount_rate = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.getDiscountRate = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.setDiscountRate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.clearDiscountRate = function() {
  return this.setDiscountRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.hasDiscountRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value discount_amount = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.getDiscountAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.clearDiscountAmount = function() {
  return this.setDiscountAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesRequest.prototype.hasDiscountAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponId: (f = msg.getProviderCouponId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest;
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderCouponId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_coupon_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.prototype.getProviderCouponId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.prototype.setProviderCouponId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.prototype.clearProviderCouponId = function() {
  return this.setProviderCouponId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponRequest.prototype.hasProviderCouponId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponId: (f = msg.getProviderCouponId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest;
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderCouponId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_coupon_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.prototype.getProviderCouponId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.prototype.setProviderCouponId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.prototype.clearProviderCouponId = function() {
  return this.setProviderCouponId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerRequest.prototype.hasProviderCouponId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponRequest.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: (f = msg.getProviderId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest;
  return proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.prototype.getProviderId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.prototype.clearProviderId = function() {
  return this.setProviderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusRequest.prototype.hasProviderId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: (f = msg.getProviderId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest;
  return proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.getProviderId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.clearProviderId = function() {
  return this.setProviderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.hasProviderId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: (f = msg.getProviderId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.OptoutRequest;
  return proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.getProviderId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.clearProviderId = function() {
  return this.setProviderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.hasProviderId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: (f = msg.getProviderId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    providerCouponFollowupReplyStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest;
  return proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderId(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus} */ (reader.readEnum());
      msg.setProviderCouponFollowupReplyStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponFollowupReplyStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.getProviderId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.clearProviderId = function() {
  return this.setProviderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.hasProviderId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus provider_coupon_followup_reply_status = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.getProviderCouponFollowupReplyStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingRequest.prototype.setProviderCouponFollowupReplyStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    digest: (f = msg.getDigest()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest;
  return proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDigest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDigest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value user_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.getUserId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue digest = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.getDigest = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.setDigest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.clearDigest = function() {
  return this.setDigest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationRequest.prototype.hasDigest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest;
  return proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse;
  return proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.CreateProviderCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse;
  return proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.DeleteProviderCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponPriceDomainsList: jspb.Message.toObjectList(msg.getProviderCouponPriceDomainsList(),
    domain_provider_coupon_price_pb.ProviderCouponPriceDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_provider_coupon_price_pb.ProviderCouponPriceDomain;
      reader.readMessage(value,domain_provider_coupon_price_pb.ProviderCouponPriceDomain.deserializeBinaryFromReader);
      msg.addProviderCouponPriceDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponPriceDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_provider_coupon_price_pb.ProviderCouponPriceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.ProviderCouponPriceDomain provider_coupon_price_domains = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderCouponPriceDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.prototype.getProviderCouponPriceDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderCouponPriceDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_coupon_price_pb.ProviderCouponPriceDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderCouponPriceDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.prototype.setProviderCouponPriceDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderCouponPriceDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderCouponPriceDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.prototype.addProviderCouponPriceDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.ProviderCouponPriceDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponPricesResponse.prototype.clearProviderCouponPriceDomainsList = function() {
  return this.setProviderCouponPriceDomainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponTarget: jspb.Message.getFieldWithDefault(msg, 1, 0),
    providerCouponTargetUserDomainsList: jspb.Message.toObjectList(msg.getProviderCouponTargetUserDomainsList(),
    domain_provider_coupon_pb.ProviderCouponTargetUserDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (reader.readEnum());
      msg.setProviderCouponTarget(value);
      break;
    case 2:
      var value = new domain_provider_coupon_pb.ProviderCouponTargetUserDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponTargetUserDomain.deserializeBinaryFromReader);
      msg.addProviderCouponTargetUserDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProviderCouponTargetUserDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_provider_coupon_pb.ProviderCouponTargetUserDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.ProviderCouponTarget provider_coupon_target = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.getProviderCouponTarget = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.setProviderCouponTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated coconala.protobuf.domain.ProviderCouponTargetUserDomain provider_coupon_target_user_domains = 2;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.getProviderCouponTargetUserDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_coupon_pb.ProviderCouponTargetUserDomain, 2));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.setProviderCouponTargetUserDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.addProviderCouponTargetUserDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetUsersResponse.prototype.clearProviderCouponTargetUserDomainsList = function() {
  return this.setProviderCouponTargetUserDomainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponTargetServiceDomainsList: jspb.Message.toObjectList(msg.getProviderCouponTargetServiceDomainsList(),
    domain_provider_coupon_pb.ProviderCouponTargetServiceDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_provider_coupon_pb.ProviderCouponTargetServiceDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponTargetServiceDomain.deserializeBinaryFromReader);
      msg.addProviderCouponTargetServiceDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponTargetServiceDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_provider_coupon_pb.ProviderCouponTargetServiceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.ProviderCouponTargetServiceDomain provider_coupon_target_service_domains = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.prototype.getProviderCouponTargetServiceDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_coupon_pb.ProviderCouponTargetServiceDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.prototype.setProviderCouponTargetServiceDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.prototype.addProviderCouponTargetServiceDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponTargetServicesResponse.prototype.clearProviderCouponTargetServiceDomainsList = function() {
  return this.setProviderCouponTargetServiceDomainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponDetailDomain: (f = msg.getProviderCouponDetailDomain()) && domain_provider_coupon_pb.ProviderCouponDetailDomain.toObject(includeInstance, f),
    targetUserCount: (f = msg.getTargetUserCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    usedCount: (f = msg.getUsedCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    providerCouponTargetUserDomainsList: jspb.Message.toObjectList(msg.getProviderCouponTargetUserDomainsList(),
    domain_provider_coupon_pb.ProviderCouponTargetUserDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse;
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_provider_coupon_pb.ProviderCouponDetailDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponDetailDomain.deserializeBinaryFromReader);
      msg.setProviderCouponDetailDomain(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTargetUserCount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUsedCount(value);
      break;
    case 4:
      var value = new domain_provider_coupon_pb.ProviderCouponTargetUserDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponTargetUserDomain.deserializeBinaryFromReader);
      msg.addProviderCouponTargetUserDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponDetailDomain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_provider_coupon_pb.ProviderCouponDetailDomain.serializeBinaryToWriter
    );
  }
  f = message.getTargetUserCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUsedCount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponTargetUserDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      domain_provider_coupon_pb.ProviderCouponTargetUserDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.ProviderCouponDetailDomain provider_coupon_detail_domain = 1;
 * @return {?proto.coconala.protobuf.domain.ProviderCouponDetailDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.getProviderCouponDetailDomain = function() {
  return /** @type{?proto.coconala.protobuf.domain.ProviderCouponDetailDomain} */ (
    jspb.Message.getWrapperField(this, domain_provider_coupon_pb.ProviderCouponDetailDomain, 1));
};


/**
 * @param {?proto.coconala.protobuf.domain.ProviderCouponDetailDomain|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.setProviderCouponDetailDomain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.clearProviderCouponDetailDomain = function() {
  return this.setProviderCouponDetailDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.hasProviderCouponDetailDomain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value target_user_count = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.getTargetUserCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.setTargetUserCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.clearTargetUserCount = function() {
  return this.setTargetUserCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.hasTargetUserCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value used_count = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.getUsedCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.setUsedCount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.clearUsedCount = function() {
  return this.setUsedCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.hasUsedCount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated coconala.protobuf.domain.ProviderCouponTargetUserDomain provider_coupon_target_user_domains = 4;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.getProviderCouponTargetUserDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_coupon_pb.ProviderCouponTargetUserDomain, 4));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.setProviderCouponTargetUserDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.addProviderCouponTargetUserDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponResponse.prototype.clearProviderCouponTargetUserDomainsList = function() {
  return this.setProviderCouponTargetUserDomainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponProviderInfoDomain: (f = msg.getProviderCouponProviderInfoDomain()) && domain_provider_coupon_pb.ProviderCouponProviderInfoDomain.toObject(includeInstance, f),
    providerCouponDetailDomain: (f = msg.getProviderCouponDetailDomain()) && domain_provider_coupon_pb.ProviderCouponDetailDomain.toObject(includeInstance, f),
    providerCouponTargetServiceDomainsList: jspb.Message.toObjectList(msg.getProviderCouponTargetServiceDomainsList(),
    domain_provider_coupon_pb.ProviderCouponTargetServiceDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse;
  return proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_provider_coupon_pb.ProviderCouponProviderInfoDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponProviderInfoDomain.deserializeBinaryFromReader);
      msg.setProviderCouponProviderInfoDomain(value);
      break;
    case 2:
      var value = new domain_provider_coupon_pb.ProviderCouponDetailDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponDetailDomain.deserializeBinaryFromReader);
      msg.setProviderCouponDetailDomain(value);
      break;
    case 3:
      var value = new domain_provider_coupon_pb.ProviderCouponTargetServiceDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponTargetServiceDomain.deserializeBinaryFromReader);
      msg.addProviderCouponTargetServiceDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponProviderInfoDomain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_provider_coupon_pb.ProviderCouponProviderInfoDomain.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponDetailDomain();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_provider_coupon_pb.ProviderCouponDetailDomain.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponTargetServiceDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      domain_provider_coupon_pb.ProviderCouponTargetServiceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.ProviderCouponProviderInfoDomain provider_coupon_provider_info_domain = 1;
 * @return {?proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.getProviderCouponProviderInfoDomain = function() {
  return /** @type{?proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} */ (
    jspb.Message.getWrapperField(this, domain_provider_coupon_pb.ProviderCouponProviderInfoDomain, 1));
};


/**
 * @param {?proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.setProviderCouponProviderInfoDomain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.clearProviderCouponProviderInfoDomain = function() {
  return this.setProviderCouponProviderInfoDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.hasProviderCouponProviderInfoDomain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.ProviderCouponDetailDomain provider_coupon_detail_domain = 2;
 * @return {?proto.coconala.protobuf.domain.ProviderCouponDetailDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.getProviderCouponDetailDomain = function() {
  return /** @type{?proto.coconala.protobuf.domain.ProviderCouponDetailDomain} */ (
    jspb.Message.getWrapperField(this, domain_provider_coupon_pb.ProviderCouponDetailDomain, 2));
};


/**
 * @param {?proto.coconala.protobuf.domain.ProviderCouponDetailDomain|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.setProviderCouponDetailDomain = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.clearProviderCouponDetailDomain = function() {
  return this.setProviderCouponDetailDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.hasProviderCouponDetailDomain = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated coconala.protobuf.domain.ProviderCouponTargetServiceDomain provider_coupon_target_service_domains = 3;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.getProviderCouponTargetServiceDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_coupon_pb.ProviderCouponTargetServiceDomain, 3));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.setProviderCouponTargetServiceDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.addProviderCouponTargetServiceDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetProviderCouponBuyerResponse.prototype.clearProviderCouponTargetServiceDomainsList = function() {
  return this.setProviderCouponTargetServiceDomainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalCount: (f = msg.getTotalCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    providerCouponListItemDomainsList: jspb.Message.toObjectList(msg.getProviderCouponListItemDomainsList(),
    domain_provider_coupon_pb.ProviderCouponListItemDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse;
  return proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalCount(value);
      break;
    case 4:
      var value = new domain_provider_coupon_pb.ProviderCouponListItemDomain;
      reader.readMessage(value,domain_provider_coupon_pb.ProviderCouponListItemDomain.deserializeBinaryFromReader);
      msg.addProviderCouponListItemDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponListItemDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      domain_provider_coupon_pb.ProviderCouponListItemDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value total_count = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.getTotalCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.clearTotalCount = function() {
  return this.setTotalCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated coconala.protobuf.domain.ProviderCouponListItemDomain provider_coupon_list_item_domains = 4;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderCouponListItemDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.getProviderCouponListItemDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderCouponListItemDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_coupon_pb.ProviderCouponListItemDomain, 4));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderCouponListItemDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.setProviderCouponListItemDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.addProviderCouponListItemDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.coconala.protobuf.domain.ProviderCouponListItemDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListProviderCouponResponse.prototype.clearProviderCouponListItemDomainsList = function() {
  return this.setProviderCouponListItemDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerCouponFollowupReplyStatus: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse;
  return proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus} */ (reader.readEnum());
      msg.setProviderCouponFollowupReplyStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderCouponFollowupReplyStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus provider_coupon_followup_reply_status = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus}
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.prototype.getProviderCouponFollowupReplyStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponFollowupReplyStatus} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetFollowupReplyStatusResponse.prototype.setProviderCouponFollowupReplyStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: (f = msg.getProviderId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    providerName: (f = msg.getProviderName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    resizableProfileIcon: (f = msg.getResizableProfileIcon()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isDeliveryStopped: (f = msg.getIsDeliveryStopped()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse;
  return proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProviderId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProviderName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setResizableProfileIcon(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDeliveryStopped(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProviderName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getResizableProfileIcon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDeliveryStopped();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value provider_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.getProviderId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.setProviderId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.clearProviderId = function() {
  return this.setProviderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.hasProviderId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue provider_name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.getProviderName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.setProviderName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.clearProviderName = function() {
  return this.setProviderName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.hasProviderName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue resizable_profile_icon = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.getResizableProfileIcon = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.setResizableProfileIcon = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.clearResizableProfileIcon = function() {
  return this.setResizableProfileIcon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.hasResizableProfileIcon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue is_delivery_stopped = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.getIsDeliveryStopped = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.setIsDeliveryStopped = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.clearIsDeliveryStopped = function() {
  return this.setIsDeliveryStopped(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.GetOptoutReplyStatusResponse.prototype.hasIsDeliveryStopped = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: (f = msg.getIsSuccess()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.OptoutResponse;
  return proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_success = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.prototype.getIsSuccess = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptoutResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.prototype.clearIsSuccess = function() {
  return this.setIsSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptoutResponse.prototype.hasIsSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasRecordsCreated: (f = msg.getHasRecordsCreated()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse;
  return proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasRecordsCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasRecordsCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue has_records_created = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.prototype.getHasRecordsCreated = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.prototype.setHasRecordsCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.prototype.clearHasRecordsCreated = function() {
  return this.setHasRecordsCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinFollowingResponse.prototype.hasHasRecordsCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: (f = msg.getIsSuccess()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse;
  return proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_success = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.prototype.getIsSuccess = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.prototype.clearIsSuccess = function() {
  return this.setIsSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.provider_coupon.OptinPreparationResponse.prototype.hasIsSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerFavoriteServiceDomainsList: jspb.Message.toObjectList(msg.getProviderFavoriteServiceDomainsList(),
    domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse;
  return proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain;
      reader.readMessage(value,domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain.deserializeBinaryFromReader);
      msg.addProviderFavoriteServiceDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderFavoriteServiceDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.ProviderFavoriteServiceDomain provider_favorite_service_domains = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain>}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.prototype.getProviderFavoriteServiceDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain>} value
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse} returns this
*/
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.prototype.setProviderFavoriteServiceDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain}
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.prototype.addProviderFavoriteServiceDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse} returns this
 */
proto.coconala.protobuf.gw.provider_coupon.ListFavoritedServiceResponse.prototype.clearProviderFavoriteServiceDomainsList = function() {
  return this.setProviderFavoriteServiceDomainsList([]);
};


goog.object.extend(exports, proto.coconala.protobuf.gw.provider_coupon);
