// package: coconala.protobuf.gw.provider_coupon
// file: gw/provider_coupon/provider_coupon.proto

import * as gw_provider_coupon_provider_coupon_pb from "../../gw/provider_coupon/provider_coupon_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as domain_enumeration_provider_coupon_target_pb from "../../domain/enumeration/provider_coupon_target_pb";
import * as domain_enumeration_provider_coupon_followup_reply_status_pb from "../../domain/enumeration/provider_coupon_followup_reply_status_pb";
import * as domain_enumeration_service_class_pb from "../../domain/enumeration/service_class_pb";
import * as domain_provider_coupon_price_pb from "../../domain/provider_coupon_price_pb";
import * as domain_provider_coupon_pb from "../../domain/provider_coupon_pb";
import * as domain_provider_favorite_service_pb from "../../domain/provider_favorite_service_pb";
export class ProviderCouponService {
  static serviceName = "coconala.protobuf.gw.provider_coupon.ProviderCouponService";
}
export namespace ProviderCouponService {
  export class CreateProviderCoupon {
    static readonly methodName = "CreateProviderCoupon";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.CreateProviderCouponRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.CreateProviderCouponResponse;
  }
  export class DeleteProviderCoupon {
    static readonly methodName = "DeleteProviderCoupon";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.DeleteProviderCouponRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.DeleteProviderCouponResponse;
  }
  export class ListProviderCouponPrices {
    static readonly methodName = "ListProviderCouponPrices";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponPricesRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponPricesResponse;
  }
  export class ListProviderCouponTargetUsers {
    static readonly methodName = "ListProviderCouponTargetUsers";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponTargetUsersRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponTargetUsersResponse;
  }
  export class ListProviderCouponTargetServices {
    static readonly methodName = "ListProviderCouponTargetServices";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponTargetServicesRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponTargetServicesResponse;
  }
  export class GetProviderCoupon {
    static readonly methodName = "GetProviderCoupon";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.GetProviderCouponRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.GetProviderCouponResponse;
  }
  export class GetProviderCouponBuyer {
    static readonly methodName = "GetProviderCouponBuyer";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.GetProviderCouponBuyerRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.GetProviderCouponBuyerResponse;
  }
  export class ListProviderCoupon {
    static readonly methodName = "ListProviderCoupon";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.ListProviderCouponResponse;
  }
  export class GetFollowupReplyStatus {
    static readonly methodName = "GetFollowupReplyStatus";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.GetFollowupReplyStatusRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.GetFollowupReplyStatusResponse;
  }
  export class GetOptoutReplyStatus {
    static readonly methodName = "GetOptoutReplyStatus";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.GetOptoutReplyStatusRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.GetOptoutReplyStatusResponse;
  }
  export class Optout {
    static readonly methodName = "Optout";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.OptoutRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.OptoutResponse;
  }
  export class OptinFollowing {
    static readonly methodName = "OptinFollowing";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.OptinFollowingRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.OptinFollowingResponse;
  }
  export class OptinPreparation {
    static readonly methodName = "OptinPreparation";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.OptinPreparationRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.OptinPreparationResponse;
  }
  export class ListFavoritedService {
    static readonly methodName = "ListFavoritedService";
    static readonly service = ProviderCouponService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_provider_coupon_provider_coupon_pb.ListFavoritedServiceRequest;
    static readonly responseType = gw_provider_coupon_provider_coupon_pb.ListFavoritedServiceResponse;
  }
}
