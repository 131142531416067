import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import grpcClient from '~/grpc/grpc-client'
import {
  secondsToTimestamp,
  Stripped,
  stripValue,
  toInt32Array,
  toInt32Value,
  toStringValue
} from '~/grpc/grpc-util'
import { ProviderCouponTarget } from '~/stub/domain/enumeration/provider_coupon_target_pb'
import {
  ProviderCouponDetailDomain,
  ProviderCouponListItemDomain,
  ProviderCouponProviderInfoDomain,
  ProviderCouponTargetServiceDomain,
  ProviderCouponTargetUserDomain
} from '~/stub/domain/provider_coupon_pb'
import { ProviderCouponPriceDomain } from '~/stub/domain/provider_coupon_price_pb'
import { ProviderFavoriteServiceDomain } from '~/stub/domain/provider_favorite_service_pb'
import {
  CreateProviderCouponRequest,
  DeleteProviderCouponRequest,
  DeleteProviderCouponResponse,
  GetFollowupReplyStatusRequest,
  GetFollowupReplyStatusResponse,
  GetOptoutReplyStatusRequest,
  GetOptoutReplyStatusResponse,
  GetProviderCouponBuyerRequest,
  GetProviderCouponBuyerResponse,
  GetProviderCouponRequest,
  GetProviderCouponResponse,
  ListFavoritedServiceRequest,
  ListFavoritedServiceResponse,
  ListProviderCouponPricesRequest,
  ListProviderCouponPricesResponse,
  ListProviderCouponRequest,
  ListProviderCouponResponse,
  ListProviderCouponTargetServicesRequest,
  ListProviderCouponTargetServicesResponse,
  ListProviderCouponTargetUsersRequest,
  ListProviderCouponTargetUsersResponse,
  OptinFollowingRequest,
  OptinFollowingResponse,
  OptoutRequest,
  OptoutResponse
} from '~/stub/gw/provider_coupon/provider_coupon_pb'
import { ProviderCouponService } from '~/stub/gw/provider_coupon/provider_coupon_pb_service'
import { ValueOf, NullableField, Overwrite } from '~/types/util'

export type ListProviderCouponRequestType = Stripped<ListProviderCouponRequest.AsObject>
export type ListProviderCouponResponseType = Stripped<ListProviderCouponResponse.AsObject>
export type ProviderCouponListItemDomainType = Stripped<ProviderCouponListItemDomain.AsObject>

export type ProviderCouponRequestType = Stripped<GetProviderCouponRequest.AsObject>
export type ProviderCouponResponseType = Stripped<GetProviderCouponResponse.AsObject>
export type ProviderCouponDetailDomainType = NullableField<
  Stripped<ProviderCouponDetailDomain.AsObject>,
  'providerFavoriteServiceDomain' | 'minimumAmount'
>
export const initialValueProviderCouponDetail = stripValue(
  new GetProviderCouponResponse().toObject()
)

/** ProviderCouponDetailDomain.DiscountDetailCaseのエイリアス */
export const DiscountTypeEnum = ProviderCouponDetailDomain.DiscountDetailCase

export type DiscountType = ValueOf<typeof DiscountTypeEnum>

export type UserInputProviderCouponDetailDomainType = ProviderCouponDetailDomainType & {
  discountType: DiscountType
}

export type ProviderCouponBuyerRequestType = Stripped<GetProviderCouponBuyerRequest.AsObject>
export type ProviderCouponBuyerResponseType = Stripped<GetProviderCouponBuyerResponse.AsObject>
export type ProviderCouponTargetServiceDomainType = Stripped<
  ProviderCouponTargetServiceDomain.AsObject
>
export type ProviderCouponProviderInfoDomainType = Stripped<
  ProviderCouponProviderInfoDomain.AsObject
>

// クーポン作成時のリクエストパラメータ
export type CreateProviderCouponRequestType = Overwrite<
  Stripped<CreateProviderCouponRequest.AsObject>,
  { providerCouponDetailDomain: ProviderCouponDetailDomainType }
>
export type ListProviderCouponTargetUsersRequestType = Stripped<
  ListProviderCouponTargetUsersRequest.AsObject
>
export type ListProviderCouponTargetUsersResponseType = Stripped<
  ListProviderCouponTargetUsersResponse.AsObject
>
export type ProviderCouponTargetUserDomainType = Stripped<ProviderCouponTargetUserDomain.AsObject>

// クーポンの削除
export type DeleteProviderCouponRequestType = Stripped<DeleteProviderCouponRequest.AsObject>
export type DeleteProviderCouponResponseType = Stripped<DeleteProviderCouponResponse.AsObject>

// オプトアウト可否確認
export type OptoutReplyStatusRequestType = Stripped<GetOptoutReplyStatusRequest.AsObject>
export type OptoutReplyStatusResponseType = Stripped<GetOptoutReplyStatusResponse.AsObject>
export const initialValueOptoutReplyStatusBuyer = stripValue(
  new GetOptoutReplyStatusResponse().toObject()
)

// オプトアウト実行
export type OptoutRequestType = Stripped<OptoutRequest.AsObject>
export type OptoutResponseType = Stripped<OptoutResponse.AsObject>

// 利用条件別の割引額リスト
export type ListProviderCouponPricesResponseType = Stripped<
  ListProviderCouponPricesResponse.AsObject
>
// 利用条件別の割引額
export type ProviderCouponDiscountPriceType = Stripped<ProviderCouponPriceDomain.AsObject>

// 対象サービス取得
export type ListProviderCouponTargetServicesRequestType = NullableField<
  Stripped<ListProviderCouponTargetServicesRequest.AsObject>,
  'minimumAmount'
>
export type ListProviderCouponTargetServicesResponseType = Stripped<
  ListProviderCouponTargetServicesResponse.AsObject
>

// メール許諾のステータス状況を取得
export type GetFollowupReplyStatusRequestType = Stripped<GetFollowupReplyStatusRequest.AsObject>
export type GetFollowupReplyStatusResponseType = Stripped<GetFollowupReplyStatusResponse.AsObject>

// フォロー時のオプトイン
export type OptinFollowingRequestType = Stripped<OptinFollowingRequest.AsObject>
export type OptinFollowingResponseType = Stripped<OptinFollowingResponse.AsObject>

/** お気に入りされたサービス */
export type ProviderFavoriteServiceDomainType = Stripped<ProviderFavoriteServiceDomain.AsObject>

export type ListFavoritedServiceRequestType = Stripped<ListFavoritedServiceResponse.AsObject>

export const initialValueListProviderCoupon = stripValue(
  new ListProviderCouponResponse().toObject()
)

export const getInitialValueListProviderCouponTargetUsers = () =>
  stripValue(new ListProviderCouponTargetUsersResponse().toObject())

export const initialValueProviderCouponDetailBuyer = stripValue(
  new GetProviderCouponBuyerResponse().toObject()
)

export const initialValueProviderCouponTargetServiceDomainsList = stripValue(
  new ListProviderCouponTargetServicesResponse().toObject()
)

export class ProviderCouponAccessor extends GrpcAccessorBase {
  /**
   * クーポン一覧の情報を取得する
   * @param param
   */
  getProviderCouponList(
    param: ListProviderCouponRequestType
  ): Promise<ListProviderCouponResponseType> {
    const metadata = this.getMetadata()
    const request = new ListProviderCouponRequest()
    request.setPage(toInt32Value(param.page))
    request.setPerPage(toInt32Value(param.perPage))
    return grpcClient({
      method: ProviderCouponService.ListProviderCoupon,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * クーポン詳細（出品者用）の情報を取得する
   * @param param
   */
  getProviderCouponDetail(param: ProviderCouponRequestType): Promise<ProviderCouponResponseType> {
    const metadata = this.getMetadata()
    const request = new GetProviderCouponRequest()
    request.setProviderCouponId(toInt32Value(param.providerCouponId))
    return grpcClient({
      method: ProviderCouponService.GetProviderCoupon,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * クーポン詳細（購入者用）の情報を取得する
   * @param param
   */
  getProviderCouponDetailBuyer(
    param: ProviderCouponBuyerRequestType
  ): Promise<ProviderCouponBuyerResponseType> {
    const metadata = this.getMetadata()
    const request = new GetProviderCouponBuyerRequest()
    request.setProviderCouponId(toInt32Value(param.providerCouponId))
    return grpcClient({
      method: ProviderCouponService.GetProviderCouponBuyer,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * クーポン対象者の一覧を取得する
   * @param param
   */
  getProviderCouponTargetUsers(
    param: ListProviderCouponTargetUsersRequestType
  ): Promise<ListProviderCouponTargetUsersRequestType> {
    const metadata = this.getMetadata()
    const request = new ListProviderCouponTargetUsersRequest()
    request.setProviderCouponTarget(param.providerCouponTarget)
    request.setServiceId(toInt32Value(param.serviceId))
    return grpcClient({
      method: ProviderCouponService.ListProviderCouponTargetUsers,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 出品者クーポンを発行または更新を行う
   * パラメーターのid（providerCouponId）がnullの時は新規作成、値がセットされている時は更新処理を行う
   * @param param APIリクエストのパラメータ
   * @param discountType 割引方法
   */
  async createProviderCoupon(
    param: CreateProviderCouponRequestType,
    discountType: DiscountType
  ): Promise<void> {
    const metadata = this.getMetadata()
    const request = new CreateProviderCouponRequest()
    const providerCouponDetailDomainRequest = this.getProviderCouponDetailDomainRequest(
      // @ts-ignore TS2345
      param.providerCouponDetailDomain,
      discountType
    )
    request.setProviderCouponDetailDomain(providerCouponDetailDomainRequest)
    request.setTargetUserIdsList(toInt32Array(param.targetUserIdsList))
    await grpcClient({
      method: ProviderCouponService.CreateProviderCoupon,
      request,
      metadata
    })
  }

  /**
   * オプトアウト可否の情報を取得する
   * @param param
   */
  getOptoutReplyStatus(
    param: OptoutReplyStatusRequestType
  ): Promise<OptoutReplyStatusResponseType> {
    const metadata = this.getMetadata()
    const request = new GetOptoutReplyStatusRequest()
    request.setProviderId(toInt32Value(param.providerId))
    request.setToken(toStringValue(param.token))
    return grpcClient({
      method: ProviderCouponService.GetOptoutReplyStatus,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * クーポン対象サービスを取得する
   * @param param
   * @param discountType
   */
  getListProviderCouponTargetServices(
    param: ListProviderCouponTargetServicesRequestType,
    discountType: DiscountType
  ): Promise<ListProviderCouponTargetServicesResponseType> {
    const metadata = this.getMetadata()
    const request = new ListProviderCouponTargetServicesRequest()
    request.setServiceClass(param.serviceClass)
    request.setMinimumAmount(toInt32Value(param.minimumAmount))
    if (discountType === DiscountTypeEnum.DISCOUNT_RATE) {
      request.setDiscountRate(toInt32Value(param.discountRate))
    } else {
      request.setDiscountAmount(toInt32Value(param.discountAmount))
    }
    return grpcClient({
      method: ProviderCouponService.ListProviderCouponTargetServices,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * オプトアウトを実行する
   * @param param
   */
  executeOptout(param: OptoutRequestType): Promise<OptoutResponseType> {
    const metadata = this.getMetadata()
    const request = new OptoutRequest()
    request.setProviderId(toInt32Value(param.providerId))
    request.setToken(toStringValue(param.token))
    return grpcClient({
      method: ProviderCouponService.Optout,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 利用条件別の割引額リストの取得
   */
  getProviderCouponDiscountPrices(): Promise<ListProviderCouponPricesResponseType> {
    const metadata = this.getMetadata()
    const request = new ListProviderCouponPricesRequest()
    return grpcClient({
      method: ProviderCouponService.ListProviderCouponPrices,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * ProviderCouponDetailDomainのリクエストインスタンスを生成しパラメータをセットして返す
   * @param param
   * @param discountType
   * @private
   */
  private getProviderCouponDetailDomainRequest(
    param: ProviderCouponDetailDomainType,
    discountType: DiscountType
  ): ProviderCouponDetailDomain {
    const request = new ProviderCouponDetailDomain()

    request.setId(toInt32Value(param.id))
    request.setProviderCouponType(param.providerCouponType)
    request.setProviderCouponTarget(param.providerCouponTarget)
    request.setServiceClass(param.serviceClass)
    // 割引方法に応じてパラメータにセットする値を返る
    if (discountType === DiscountTypeEnum.DISCOUNT_RATE) {
      request.setDiscountRate(toInt32Value(param.discountRate))
    } else {
      request.setDiscountAmount(toInt32Value(param.discountAmount))
    }
    request.setMinimumAmount(toInt32Value(param.minimumAmount))
    // @ts-ignore TS2532
    request.setDeliveryDate(secondsToTimestamp(param.deliveryDate.seconds))
    // @ts-ignore TS2532
    request.setExpireDate(secondsToTimestamp(param.expireDate.seconds))
    request.setMessage(toStringValue(param.message))
    request.setProviderCouponStatus(param.providerCouponStatus)
    if (param.providerFavoriteServiceDomain) {
      request.setProviderFavoriteServiceDomain(
        this.getProviderFavoriteServiceDomain(param.providerFavoriteServiceDomain)
      )
    }

    return request
  }

  /**
   * ProviderFavoriteServiceDomainを生成
   * @param param
   */
  private getProviderFavoriteServiceDomain(
    param: ProviderFavoriteServiceDomainType
  ): ProviderFavoriteServiceDomain {
    const providerFavoriteServiceDomain = new ProviderFavoriteServiceDomain()
    providerFavoriteServiceDomain.setId(toInt32Value(param.id))
    providerFavoriteServiceDomain.setTitle(toStringValue(param.title))
    providerFavoriteServiceDomain.setPrice(toInt32Value(param.price))
    providerFavoriteServiceDomain.setFavoriteCount(toInt32Value(param.favoriteCount))
    providerFavoriteServiceDomain.setServiceClass(param.serviceClass)
    providerFavoriteServiceDomain.setServiceImagePath(toStringValue(param.serviceImagePath))
    providerFavoriteServiceDomain.setMovieImageUrl(toStringValue(param.movieImageUrl))
    providerFavoriteServiceDomain.setUnitTime(toInt32Value(param.unitTime))
    return providerFavoriteServiceDomain
  }

  /**
   * 出品者クーポンを削除する
   * @param param APIリクエストのパラメータ
   */
  deleteProviderCoupon(
    param: DeleteProviderCouponRequestType
  ): Promise<DeleteProviderCouponResponseType> {
    const metadata = this.getMetadata()
    const request = new DeleteProviderCouponRequest()
    request.setProviderCouponId(toInt32Value(param.providerCouponId))
    return grpcClient({
      method: ProviderCouponService.DeleteProviderCoupon,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * メール許諾のステータス状況を取得
   * @param params APIリクエストのパラメータ
   */
  getFollowupReplyStatus(
    params: GetFollowupReplyStatusRequestType
  ): Promise<GetFollowupReplyStatusResponseType> {
    const metadata = this.getMetadata()
    const request = new GetFollowupReplyStatusRequest()
    request.setProviderId(toInt32Value(params.providerId))
    return grpcClient({
      method: ProviderCouponService.GetFollowupReplyStatus,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * フォロー時のオプトイン
   * @param params APIリクエストのパラメータ
   */
  optinFollowing(params: OptinFollowingRequestType): Promise<OptinFollowingResponseType> {
    const metadata = this.getMetadata()
    const request = new OptinFollowingRequest()
    request.setProviderId(toInt32Value(params.providerId))
    request.setProviderCouponFollowupReplyStatus(params.providerCouponFollowupReplyStatus)
    return grpcClient({
      method: ProviderCouponService.OptinFollowing,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * お気に入りされているサービス一覧取得
   */
  getProviderFavoriteServiceList(): Promise<ListFavoritedServiceRequestType> {
    const metadata = this.getMetadata()
    const request = new ListFavoritedServiceRequest()
    return grpcClient({
      method: ProviderCouponService.ListFavoritedService,
      request,
      metadata,
      strip: true
    })
  }
}
